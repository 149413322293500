import { computed, ref, watch } from "@vue/composition-api";

import { $themeConfig } from "@themeConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import i18n from "@/libs/i18n";
import router from "@/router";
import store from "@/store";
import { useToast } from "vue-toastification/composition";

export default function useAddCampaign() {
  const toast = useToast();
  const initialForm = ref({
    campaign_budget: 0,
    status: 1,
    apply_with_other_voucher: 1,
    apply_with_hodo_credit: 1,
    discount_by_order_amount: 1,
  });
  const voucherTypes = ref([
    { text: "VNĐ", value: 1 },
    { text: "%", value: 2 },
  ]);
  const refVouchersTable = ref(null);
  const refVoucherGroupsTable = ref(null);
  const refProductsTable = ref(null);
  const form = ref(initialForm.value);
  const detailCampaign = ref({});
  const currentPage = ref(1);
  const perPage = ref(10);

  const vouchers = ref([]);
  const voucherAll = ref([]);
  const voucherGroups = ref([]);
  const currentPageVoucherGroups = ref(1);
  const perPageVoucherGroups = ref(10);
  const totalVoucherGroups = ref(0);
  const searchQueryVoucherGroups = ref("");

  const products = ref([]);
  const totalProducts = ref(0);

  const domain = ref($themeConfig.app.domain);
  const campaignId = ref(null);
  const addCampaignValidation = ref(null);
  const isLoading = ref(false);
  const isLoadingVoucher = ref(false);
  const isLoadingVoucherGroup = ref(false);
  const isLoadingProduct = ref(false);

  const searchQuery = ref("");
  const totalVouchers = ref(0);
  const timeout = ref(null);
  const showTable = ref(false);

  const searchQueryProducts = ref("");
  const currentPageProducts = ref(1);
  const perPageProducts = ref(10);

  const dataMetaVoucher = computed(() => {
    const localItemsCount = refVouchersTable.value
      ? refVouchersTable.value.localItems.length
      : 0;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalVouchers.value,
    };
  });

  const dataMetaVoucherGroup = computed(() => {
    const localItemsCount = refVoucherGroupsTable.value
      ? refVoucherGroupsTable.value.localItems.length
      : 0;

    return {
      from:
        perPageVoucherGroups.value * (currentPageVoucherGroups.value - 1) +
        (localItemsCount ? 1 : 0),
      to:
        perPageVoucherGroups.value * (currentPageVoucherGroups.value - 1) +
        localItemsCount,
      of: totalVoucherGroups.value,
    };
  });

  const dataMetaProduct = computed(() => {
    const localItemsCount = refProductsTable.value
      ? refProductsTable.value.localItems.length
      : 0;

    return {
      from:
        perPageProducts.value * (currentPageProducts.value - 1) +
        (localItemsCount ? 1 : 0),
      to:
        perPageProducts.value * (currentPageProducts.value - 1) +
        localItemsCount,
      of: totalProducts.value,
    };
  });

  watch(perPage, () => {
    currentPage.value = 1;
  });

  watch([currentPage, perPage, searchQuery], () => {
    showTable.value = true;
    clearTimeout(timeout.value);
    timeout.value = setTimeout(() => {
      refetchData();
      showTable.value = false;
    }, 1000);
  });
  const addCampaign = () => {
    const userData = appUtils.getLocalUser();

    if (!userData.supplierInfo?.org_id) return;
    addCampaignValidation.value.validate().then(async (success) => {
      if (success) {
        isLoading.value = true;
        const { ...newForm } = form.value;

        const params = {
          ...newForm,
          org_id: userData.supplierInfo.org_id,
          campaign_budget: parseInt(newForm.campaign_budget || 0),
          start_date: newForm.start_date
            ? moment(newForm.start_date).startOf("day").valueOf()
            : newForm.start_date,
          end_date: newForm.end_date
            ? moment(newForm.end_date).endOf("day").valueOf()
            : newForm.end_date,
        };
        store
          .dispatch("campaigns/addCampaign", params)
          .then(async (response) => {
            const campaign = response;
            console.log(campaign);
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("campaigns.lbl_add_new_campaign_success"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            router.push({
              path: `/campaigns`,
            });
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    });
  };

  const updateCampaign = () => {
    addCampaignValidation.value.validate().then(async (success) => {
      if (success) {
        isLoading.value = true;
        const { ...newForm } = form.value;

        const params = {
          ...newForm,
          start_date: newForm.start_date
            ? moment(newForm.start_date).startOf("day").valueOf()
            : newForm.start_date,
          end_date: newForm.end_date
            ? moment(newForm.end_date).endOf("day").valueOf()
            : newForm.end_date,
        };
        console.log("params :", params);
        store
          .dispatch("campaigns/updateCampaign", params)
          .then(async () => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("campaigns.lbl_update_new_campaign_success"),
                icon: "CheckIcon",
                variant: "success",
              },
            });

            router.push({
              path: `/campaigns`,
            });
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    });
  };

  const refetchData = () => {
    console.log("refresh");
    refVouchersTable.value.refresh();
  };

  return {
    store,
    refVouchersTable,
    refVoucherGroupsTable,
    refProductsTable,
    domain,
    initialForm,
    form,
    detailCampaign,
    voucherTypes,

    vouchers,
    voucherAll,
    voucherGroups,
    products,

    addCampaignValidation,
    campaignId,
    addCampaign,
    updateCampaign,
    isLoading,
    isLoadingVoucher,
    isLoadingVoucherGroup,
    isLoadingProduct,

    dataMetaVoucher,
    dataMetaVoucherGroup,
    dataMetaProduct,

    totalVouchers,
    totalVoucherGroups,
    totalProducts,
    currentPageVoucherGroups,
    perPageVoucherGroups,
    searchQueryVoucherGroups,

    currentPage,
    perPage,
    searchQueryProducts,
    currentPageProducts,
    perPageProducts,

    timeout,
    refetchData,
    showTable,
    searchQuery,
  };
}
