<template>
  <div>
    <div class="w-full table-product-groups">
      <div class="flex items-center justify-between mb-2">
        <h5
          class="border-left-primary pl-50 mb-0"
          style="border-left-width: 4px"
        >
          {{ "Sản phẩm áp dụng" }}
        </h5>
        <b-button
          variant="link"
          class="m-0items-center gap-2"
          style="display: flex !important"
          :disabled="!campaign.id"
          @click="openModalProduct"
          ><PlusCircleIcon size="14" />{{
            `${$t("lbl_add_new")} Sản phẩm`
          }}</b-button
        >
      </div>
      <div class="table-product relative">
        <b-button
          v-if="productGroupSelected.length > 0"
          class="absolute right-0"
          style="z-index: 1; margin-right: 30px; position: absolute !important"
          variant="outline-danger"
          size="sm"
          @click.prevent="onDeleteAllSelectedProduct()"
        >
          <feather-icon size="16" icon="Trash2Icon" />
        </b-button>
        <LayoutDataTable
          v-if="products.length || searchQueryProducts"
          :isLoading="isLoading"
          :searchQuery="searchQueryProducts"
          :perPage="perPageProducts"
          :meta="dataMetaProduct"
          :currentPage="currentPageProducts"
          :totals="totalProducts"
          :searchPlaceholder="$t('lbl_search')"
          @setPerPage="
            (value) => ((perPageProducts = value), (currentPageProducts = 1))
          "
          @setCurrentPage="(value) => (currentPageProducts = value)"
          @setSearchQuery="
            (value) => (
              (searchQueryProducts = value), (currentPageProducts = 1)
            )
          "
        >
          <template #default>
            <b-form-checkbox-group
              id="checkbox-group-product-name"
              v-model="productGroupSelected"
              name="product-name"
            >
              <b-table
                ref="refProductsTable"
                striped
                hover
                empty-text="Không có dữ liệu"
                :items="products"
                :fields="productsFields"
              >
                <!-- HEADER -->
                <template #head(name)="scope">
                  <div class="d-flex">
                    <b-form-checkbox
                      class="mr-1"
                      :value="products.map((item) => item.id)"
                      @change="onSelectProduct"
                    />
                    <span>{{ scope.label }}</span>
                  </div>
                </template>
                <!-- ROW -->
                <template #cell(name)="data">
                  <div class="d-flex">
                    <b-form-checkbox class="mr-1" :value="data.item.id" />

                    <b>{{ data.item.name || "--" }}</b>
                  </div>
                </template>
                <template #cell(action)="data">
                  <div class="text-right">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      class="ml-1"
                      variant="outline-danger"
                      size="sm"
                      :disabled="productGroupSelected.length > 0"
                      @click.stop="removeProduct(data.item)"
                    >
                      <feather-icon size="16" icon="Trash2Icon" />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-form-checkbox-group>
          </template>
        </LayoutDataTable>
      </div>
      <ProductModal
        :is-show-product-modal.sync="isShowModalProduct"
        @commitData="commitData"
      />
    </div>
  </div>
</template>

<script>
import ProductModal from "@/components/Campaign/ProductModal.vue";
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import i18n from "@/libs/i18n";
import appUtils from "@/utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import moment from "moment";
import Vue from "vue";
import { PlusCircleIcon } from "vue-feather-icons";
import Ripple from "vue-ripple-directive";
import useActionsCampaigns from "../../views/Campaigns/_id/useActionsCampaigns";

export default {
  components: {
    LayoutDataTable,
    PlusCircleIcon,
    ProductModal,
  },
  directives: {
    Ripple,
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  async created() {
    if (this.$route.params.id !== "add") {
      this.fetchProducts();
    }
  },
  computed: {},

  data() {
    return {
      required,
      appUtils,
      isShowModalProduct: false,
      productGroupSelected: [],
      isAllSelectProduct: false,
      productsFields: [
        { key: "name", label: "Tên dịch vụ/ sản phẩm" },
        { key: "action", label: "" },
        // { key: "created_at", label: "Ngày tạo" },
        // { key: "updated_at", label: "Ngày cập nhật" },
      ],
    };
  },
  watch: {
    currentPageProducts(newVal) {
      console.log(newVal);
      this.currentPageProducts = newVal;
      this.fetchProducts();
    },
    perPageProducts(newVal) {
      this.perPageProducts = newVal;
      this.fetchProducts();
    },
    searchQueryProducts(newVal) {
      this.searchQueryProducts = newVal;
      this.fetchProducts();
    },
  },

  mounted() {},
  methods: {
    moment,
    openModalProduct() {
      this.isShowModalProduct = true;
    },
    closeModalProduct() {
      console.log("closeModalProduct");
      this.productGroupSelected = {};
      this.isShowModalProduct = false;
    },
    async commitData() {
      this.fetchProducts();
    },
    onSelectProduct() {
      this.isAllSelectProduct = !this.isAllSelectProduct;
      if (this.isAllSelectProduct) {
        this.productGroupSelected = this.products.map((item) => item.id);
      } else {
        this.productGroupSelected = [];
      }
    },
    async onDeleteAllSelectedProduct() {
      const self = this;

      const h = this.$createElement;
      const messageVNode = h("div", { class: [] }, [
        "Bạn có chắc muốn xoá ",
        h("strong", `"Tất cả sản phẩm đã chọn"`),
        " ra khỏi danh sách sản phẩm áp dụng của campaign này?",
      ]);

      self.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Xác nhận xóa",
          size: "sm",
          okVariant: "danger",
          okTitle: "Xác nhận",
          cancelTitle: "Huỷ bỏ",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await self.addProductsToProductGroup(this.productGroupSelected);
            this.productGroupSelected = [];
            this.isAllSelectProduct = false;
            await self.fetchProducts();
          }
        })
        .catch(() => {});
    },
    async fetchProducts() {
      this.isLoadingProduct = true;
      const userData = appUtils.getLocalUser();

      if (!userData || !userData?.supplierInfo?.id) return;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getProductGroups({
          page_size: this.perPageProducts,
          page: this.currentPageProducts,
          search: this.searchQueryProducts,
          campaign_id: parseInt(this.$route.params.id),
          supplier_id: userData?.supplierInfo?.id,
        })
        .then((response) => {
          const { data, total } = response.data || [];
          this.totalProducts = total;
          this.products = data;
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingProduct = false;
        });
    },
    async addProductsToProductGroup(removeIds) {
      if (!removeIds.length) return;
      try {
        await Vue.prototype.$rf.getRequest("CampaignRequest").addProducts({
          voucher_campaign_id: parseInt(this.$route.params.id),
          remove_product_id: removeIds,
        });
      } catch (error) {
        console.error("Error add products to product group:", error);
        this.showToastError();
        throw error; // Rethrow error to handle in handleSubmit
      }
    },
    removeProduct(data) {
      const self = this;

      const h = this.$createElement;
      const messageVNode = h("div", { class: [] }, [
        "Bạn có chắc muốn xoá ",
        h("strong", `"${data.name}"`),
        " ra khỏi danh sách sản phẩm áp dụng của campaign này?",
      ]);

      self.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Xác nhận xóa",
          size: "sm",
          okVariant: "danger",
          okTitle: "Xác nhận",
          cancelTitle: "Huỷ bỏ",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await self.addProductsToProductGroup([data?.id]);
            await self.fetchProducts();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.table-product-groups {
  .m-2 {
    margin: 0 0 12px 0 !important;
  }
  .card {
    width: 100%;
  }
  .pagination-customize {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
