<template>
  <div class="mt-1">
    <b-modal
      size="sm"
      :visible="isShowProductModal"
      :title="form.id ? 'Chỉnh sửa sản phẩm' : 'Thêm sản phẩm'"
      centered
      cancel-variant="outline-secondary"
      :hide-footer="true"
      @hide="hideMethod"
      @change="(val) => $emit('update:is-show-product-modal', val)"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <div class="d-flex items-end align-items-end gap-1">
          <b-form-group label="Tìm kiếm" style="flex: 1">
            <b-form-input
              placeholder="Nhập tên sản phẩm"
              v-model="searchQuery"
              @input="handleSearch"
            />
          </b-form-group>
          <b-form-group label="">
            <b-form-select
              v-model="type"
              :options="productTypes"
              @change="handleTypeChange"
            ></b-form-select>
          </b-form-group>
        </div>
        <b-form-group label="Sản phẩm áp dụng" class="mb-2 required">
          <div
            class="relative min-h-250 scroll-container"
            ref="scrollContainer"
            @scroll="handleScroll"
          >
            <b-form-checkbox-group
              id="checkbox-group-products"
              v-model="form.products"
              name="flavour-1"
              :class="isloadingProduct ? 'opacity-5' : ''"
            >
              <div
                class="d-inline-block w-full"
                style="margin-bottom: 4px"
                v-for="product in products"
                :key="product.value"
              >
                <b-form-checkbox :value="product.id" class="mr-2">
                  {{ product.name }}
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
            <div
              v-if="isloadingProduct"
              class="justify-center items-center flex w-100 absolute"
              style="
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              "
            >
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </div>
        </b-form-group>
        <div class="d-flex mt-2">
          <div class="ml-auto">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              @click="$emit('update:is-show-product-modal', false)"
              >Hủy</b-button
            >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :loading="loading"
              @click="handleSubmit"
              >{{ "Thêm" }}</b-button
            >
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import i18n from "@/libs/i18n";
import store from "@/store";
import appUtils from "@/utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  BOverlay,
  VBModal,
} from "bootstrap-vue";
import { debounce } from "lodash";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import useActionsCampaigns from "../../views/Campaigns/_id/useActionsCampaigns";

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    isShowProductModal: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  data() {
    return {
      form: {
        products: [],
      },
      required,
      loading: false,
      isloadingProduct: false,
      page_num: 1,
      hasMoreProducts: true,
      type: null,
      products: [],
      productSelecteds: [],
      productTypes: [
        { value: null, text: "Tất cả" },
        { value: 1, text: "Sản phẩm" },
        { value: 2, text: "Dịch vụ" },
      ],
    };
  },
  watch: {
    // Watch for changes to the `data` prop
    isShowProductModal: {
      immediate: true, // Trigger this watcher when component is mounted
      handler(newValue) {
        if (newValue) {
          this.fetchProducts();
          this.fetchProductsSelected();
        }
      },
    },
  },
  methods: {
    hideMethod() {
      this.$emit("closeModalProduct");
    },

    handleSearch: debounce(function (query) {
      this.searchQuery = query;
      this.page_num = 1;
      this.products = [];
      this.hasMoreProducts = true;
      this.fetchProducts();
    }, 300),

    handleTypeChange() {
      this.fetchProducts();
    },

    async fetchProducts() {
      if (this.isloadingProduct || !this.hasMoreProducts) return;

      this.isloadingProduct = true;
      const userData = appUtils.getLocalUser();

      if (!userData || !userData?.supplierInfo?.id) return;

      try {
        const response = await store.dispatch("products/fetchProductsList", {
          keyword: this.searchQuery,
          supplier_id: userData?.supplierInfo?.id,
          page_num: this.page_num,
          page_size: 12,
          is_added_inventory: 1,
          is_available: 1,
          is_hodo_active: 1,
        });

        const newProducts = response.result?.data || [];

        if (newProducts.length) {
          this.products = [...this.products, ...newProducts];
          this.page_num += 1;
        } else {
          this.hasMoreProducts = false;
        }
      } catch (e) {
        console.log(e);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.isloadingProduct = false;
      }
    },

    async addProductsToProductGroup() {
      try {
        await Vue.prototype.$rf.getRequest("CampaignRequest").addProducts({
          voucher_campaign_id: parseInt(this.$route.params.id),
          product_id: this.form.products,
          remove_product_id: this.productSelecteds.filter(
            (id) => !this.form.products.includes(id)
          ),
        });
      } catch (error) {
        console.error("Error add products to product group:", error);
        this.showToastError();
        throw error; // Rethrow error to handle in handleSubmit
      }
    },

    async fetchProductsSelected() {
      this.isLoading = true;
      const userData = appUtils.getLocalUser();

      if (!userData || !userData?.supplierInfo?.id) return;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getProductGroups({
          campaign_id: this.$route.params.id,
          supplier_id: userData?.supplierInfo?.id,
        })
        .then((response) => {
          const { data } = response.data || {};
          this.form.products = data.map((e) => e.id);
          this.productSelecteds = data.map((e) => e.id);
        })
        .catch((error) => {
          console.error("Error get products from product group:", error);
          this.showToastError();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showToastError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_error_fetching_data"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    handleSelectProducts(checkedProducts) {
      this.form.products = checkedProducts;
    },

    async handleSubmit() {
      this.loading = true;
      try {
        await this.addProductsToProductGroup();
        // Emit to close the modal and commit data
        this.$emit("commitData");
        this.$emit("update:is-show-product-modal", false);
      } catch (error) {
        // Error already handled in individual functions
      } finally {
        this.loading = false;
      }
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;

      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - 10
      ) {
        this.fetchProducts();
      }
    },
  },
};
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
.collapsible {
  overflow: hidden;
  height: 0; /* Start with height 0 */
  transition: height 100ms ease; /* Smooth height transition */
}

.collapsible.is-open {
  height: auto; /* Allow height to expand */
}

.content {
  padding: 10px; /* Padding for content */
}
.scroll-container {
  min-height: 250px;
  max-height: 300px;
  overflow-y: auto;
  position: relative;
  border: 1px solid #ddd;
  padding: 10px;
}
.opacity-5 {
  opacity: 40%;
}
</style>
