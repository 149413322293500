<template>
  <div>
    <div class="w-full table-voucher-groups">
      <div class="flex items-center justify-between mb-2">
        <h5
          class="border-left-primary pl-50 mb-0"
          style="border-left-width: 4px"
        >
          {{ "Danh sách Nhóm Vouchers" }}
        </h5>
        <b-button
          variant="link"
          class="m-0items-center gap-2"
          style="display: flex !important"
          :disabled="!campaign.id"
          @click="
            () => {
              openModalVoucher();
              voucherGroupSelected = {};
            }
          "
          ><PlusCircleIcon size="14" />{{
            `${$t("lbl_add_new")} Nhóm Vouchers`
          }}</b-button
        >
      </div>
      <LayoutDataTable
        v-if="voucherGroups.length || searchQueryVoucherGroups"
        :isLoading="isLoading"
        :searchQuery="searchQueryVoucherGroups"
        :perPage="perPageVoucherGroups"
        :meta="dataMetaVoucherGroup"
        :currentPage="currentPageVoucherGroups"
        :totals="totalVoucherGroups"
        :searchPlaceholder="$t('lbl_search')"
        @setPerPage="
          (value) => (
            (perPageVoucherGroups = value), (currentPageVoucherGroups = 1)
          )
        "
        @setCurrentPage="(value) => (currentPageVoucherGroups = value)"
        @setSearchQuery="
          (value) => (
            (searchQueryVoucherGroups = value), (currentPageVoucherGroups = 1)
          )
        "
      >
        <template #default>
          <b-table
            ref="refVoucherGroupsTable"
            class="position-relative"
            striped
            hover
            empty-text="Không có dữ liệu"
            :items="voucherGroups"
            :fields="voucherGroupsFields"
            @row-clicked="handleRowClick"
          >
            <template #cell(name)="data">
              <div class="d-flex items-center">
                <b>{{ data.item.name || "--" }}</b>
              </div>
            </template>
            <template #cell(start_date)="data">
              <span>
                {{
                  data.item.start_date === ""
                    ? appUtils.formatDate(campaign.start_date)
                    : appUtils.formatDate(data.item.start_date)
                }}</span
              >
            </template>
            <template #cell(end_date)="data">
              <span>
                {{
                  data.item.end_date === ""
                    ? appUtils.formatDate(campaign.end_date)
                    : appUtils.formatDate(data.item.end_date)
                }}</span
              >
            </template>
            <template #cell(voucher_value)="data">
              <span>
                {{
                  `${appUtils.numberFormat(data.item.voucher_value)} ${
                    data.item.voucher_type === 1 ? "VNĐ" : "%"
                  }`
                }}
              </span>
            </template>
            <template #cell(max_voucher_value)="data">
              <span
                >{{
                  appUtils.numberFormat(data.item.max_voucher_value)
                }}
                VNĐ</span
              >
            </template>
            <template #cell(status)="data">
              <div :set="(statusCampaign = getStatusCampaign(data.item))">
                <span :class="statusCampaign.color">{{
                  statusCampaign.text
                }}</span>
              </div>
            </template>
            <!-- ACTION COLUMN -->
            <template #cell(action)="data">
              <div
                class="action text-right text-nowrap"
                v-if="data.item.status === 1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="isLoading"
                  size="sm"
                  @click="editVoucherGroup(data.item)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit"
                  >
                    <path
                      d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                    ></path>
                    <path
                      d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                    ></path>
                  </svg>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  class="ml-1"
                  variant="outline-danger"
                  size="sm"
                  @click="updateStatusVoucherGroup(data.item)"
                >
                  <feather-icon size="16" icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </template>
      </LayoutDataTable>
      <VoucherModal
        :is-show-voucher-modal.sync="isShowModalVoucher"
        :data="voucherGroupSelected"
        :campaign="campaign"
        :typeModal="voucherGroupTypeModal"
        @commitData="commitData"
        @close="closeModalVoucher"
      />
    </div>
  </div>
</template>

<script>
import VoucherModal from "@/components/Campaign/VoucherModal.vue";
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import i18n from "@/libs/i18n";
import appUtils from "@/utils/appUtils";
import { TYPE_MODAL, VOUCHER_GROUP_STATUS } from "@/utils/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import Vue from "vue";
import { PlusCircleIcon } from "vue-feather-icons";
import Ripple from "vue-ripple-directive";
import useActionsCampaigns from "../../views/Campaigns/_id/useActionsCampaigns";

export default {
  components: {
    LayoutDataTable,
    PlusCircleIcon,
    VoucherModal,
  },
  directives: {
    Ripple,
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  async created() {
    if (this.$route.params.id !== "add") {
      this.fetchVoucherGroups();
    }
  },
  computed: {},

  data() {
    return {
      required,
      appUtils,
      isShowModalVoucher: false,
      voucherGroupSelected: {},
      rowSelected: {},
      voucherGroupTypeModal: TYPE_MODAL.CREATE,
      voucherGroupsFields: [
        { key: "name", label: "Tên voucher" },
        {
          key: "start_date",
          label: "Ngày bắt đầu",
        },
        {
          key: "end_date",
          label: "Ngày kết thúc",
        },
        { key: "voucher_value", label: "Giá trị" },
        { key: "max_voucher_value", label: "Giá trị tối đa" },
        { key: "status", label: "Trạng thái" },
        { key: "action", label: "", thClass: "text-right", stickyColumn: true },
      ],
    };
  },
  watch: {
    currentPageVoucherGroups(newVal) {
      console.log(newVal);
      this.currentPageVoucherGroups = newVal;
      this.fetchVoucherGroups();
    },
    perPageVoucherGroups(newVal) {
      this.perPageVoucherGroups = newVal;
      this.fetchVoucherGroups();
    },
    searchQueryVoucherGroups(newVal) {
      this.searchQueryVoucherGroups = newVal;
      this.fetchVoucherGroups();
    },
  },

  mounted() {},
  methods: {
    openModalVoucher() {
      this.voucherGroupTypeModal = TYPE_MODAL.CREATE;
      Object.assign(this.voucherGroupSelected, {});
      this.isShowModalVoucher = true;
    },
    closeModalVoucher() {
      console.log("closeModalVoucher");
      this.voucherGroupSelected = {};
      this.isShowModalVoucher = false;
    },
    editVoucherGroup(item) {
      this.voucherGroupTypeModal = TYPE_MODAL.EDIT;
      this.voucherGroupSelected = item;
      this.isShowModalVoucher = true;
    },
    selectRowEdit(item) {
      this.rowSelected = item;
      this.isShowModalVoucher = false;
      console.log(item);
    },
    async commitData() {
      this.fetchVoucherGroups();
    },
    async fetchVoucherGroups() {
      this.isLoadingVoucherGroup = true;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getVoucherGroups(this.$route.params.id, {
          page_size: this.perPageVoucherGroups,
          page: this.currentPageVoucherGroups,
          search: this.searchQueryVoucherGroups,
        })
        .then((response) => {
          const { data, total } = response.data || [];
          this.totalVoucherGroups = total;
          this.voucherGroups = data.map((e) => ({
            id: e.id,
            name: e.name,
            voucher_value: e.voucher_value,
            voucher_type: e.voucher_type,
            max_voucher_value: e.max_voucher_value,
            start_date: e.start_date === 0 ? "" : new Date(e.start_date),
            end_date: e.end_date === 0 ? "" : new Date(e.end_date),
            status: e.status,
            created_at: e.created_at,
          }));
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingVoucherGroup = false;
        });
    },

    async fetchVouchers() {
      this.isLoadingVoucher = true;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getVouchers({
          page_size: this.perPageVoucherGroups,
          page: this.currentPageVoucherGroups,
          search: this.searchQueryVoucherGroups,
          campaign_id: this.$route.params.id,
        })
        .then((response) => {
          const { data, total } = response.data || [];
          this.totalVouchers = total;
          this.vouchers = data.map((e) => ({
            code: e.code,
            created_at: e.created_at,
            is_redeemed: e.is_redeemed,
          }));
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingVoucher = false;
        });
    },

    async updateStatusVoucherGroup(item) {
      this.$bvModal
        .msgBoxConfirm(
          "Hành động này sẽ không thể hoàn tác, xin vui lòng xác nhận chắc chắn muốn huỷ?",
          {
            title: "Bạn muốn huỷ nhóm voucher?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Xác nhận",
            cancelTitle: "Huỷ bỏ",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            try {
              await Vue.prototype.$rf
                .getRequest("CampaignRequest")
                .updateStatusVoucherGroup({
                  id: item.id,
                  status: item.status === 1 ? 2 : 1,
                });

              await this.fetchVoucherGroups();
            } catch (error) {
              console.error("Error update status voucher group:", error);
              this.showToastError();
              throw error; // Rethrow error to handle in handleSubmit
            }
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },

    handleRowClick(item) {
      this.voucherGroupTypeModal = TYPE_MODAL.VIEW;
      this.voucherGroupSelected = item;
      this.isShowModalVoucher = true;
    },
    showToastError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message ? i18n.t(message) : i18n.t("lbl_error_fetching_data"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    getStatusCampaign(data) {
      var start_date = data.start_date || this.campaign.start_date;
      var end_date = data.end_date || this.campaign.end_date;

      if (data.status == VOUCHER_GROUP_STATUS.ACTIVE) {
        if (Number(start_date) <= Date.now() && Number(end_date) > Date.now()) {
          return {
            text: "Đang hoạt động",
            color: "text-success",
          };
        } else {
          return {
            text: "Đã hết hạn",
            color: "text-warning",
          };
        }
      } else {
        return {
          text: "Đã hủy",
          color: "text-danger",
        };
      }
    },
  },
};
</script>

<style lang="scss">
.table-voucher-groups {
  .m-2 {
    margin: 0 0 12px 0 !important;
  }
  .card {
    width: 100%;
  }
  .pagination-customize {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  /* Hide icon by default */
  .hover-icon {
    transition: all 0.1s ease-in-out;
    visibility: hidden;
  }

  /* Show icon when row is hovered */
  .b-table tbody tr:hover .hover-icon {
    visibility: visible;
  }
}
</style>
