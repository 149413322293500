<template>
  <div class="w-full relative table-vouchers">
    <b-button
      class="absolute right-0"
      style="z-index: 99"
      variant="link"
      @click="handleExportExcelFile"
      >Download CSV</b-button
    >
    <LayoutDataTable
      :isLoading="isLoadingVoucher"
      :searchQuery="searchQuery"
      :perPage="perPage"
      :meta="dataMetaVoucher"
      :currentPage="currentPage"
      :totals="totalVouchers"
      :searchPlaceholder="$t('lbl_search')"
      @setPerPage="(value) => ((perPage = value), (currentPage = 1))"
      @setCurrentPage="(value) => (currentPage = value)"
      @setSearchQuery="(value) => ((searchQuery = value), (currentPage = 1))"
    >
      <template #default>
        <b-table
          ref="refVouchersTable"
          striped
          hover
          empty-text="Không có dữ liệu"
          :fields="voucherFields"
          :items="vouchers"
        >
          <template #cell(code)="data">
            <div
              class="d-flex items-center"
              @click="() => copyCode(data.item.code)"
            >
              <b>{{ data.item.code }} </b>
              <svg
                class="transition-all duration-300"
                style="margin-left: 4px"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  stroke="#20419b"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                ></path>
                <path
                  stroke="#20419b"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M22 6.9v4.2c0 3.5-1.4 4.9-4.9 4.9H16v-3.1C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2h4.2C20.6 2 22 3.4 22 6.9z"
                ></path>
              </svg>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span> {{ appUtils.formatDate(data.item.created_at) }}</span>
          </template>
          <template #cell(is_redeemed)="data">
            <div :set="(status = getStatusVoucher(data.item))">
              <span :class="status.color">{{ status.text }}</span>
            </div>
          </template>
          <!-- QR Code Column -->
          <template #cell(qr_code)="data">
            <div class="d-flex justify-center">
              <svg
                @click="openQrModal(data.item)"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                style="cursor: pointer; margin-left: 4px"
              >
                <path
                  stroke="#20419b"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M4 4h5v5H4zM4 15h5v5H4zM15 4h5v5h-5zM10 10h4v4h-4zM15 15h5v5h-5z"
                ></path>
              </svg>
            </div>
          </template>
        </b-table>
      </template>
    </LayoutDataTable>
    <!-- Modal Component for QR Code -->
    <b-modal ref="qrModal" title="QR Code" centered>
      <div ref="printMe" class="d-flex justify-center">
        <!-- QR Code generated by VueQrcode component -->
        <figure class="qrcode w-full h-full md:w-[512px] md:h-[512px]">
          <qrcode
            ref="qrCode"
            class="!w-full !h-full"
            :value="qrCodeData"
            tag="canvas"
            :options="{
              errorCorrectionLevel: 'Q',
              width: 200,
            }"
          ></qrcode>
          <img
            class="qrcode__image"
            src="@/assets/images/img-hodo.webp"
            alt="HODO"
          />
        </figure>
      </div>
      <!-- Custom Footer -->
      <template #modal-footer>
        <b-button variant="primary" class="w-full" @click="handleDownloadQrCode"
          >Download QR Code</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import i18n from "@/libs/i18n";
import appUtils from "@/utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import moment from "moment";
import QRCode from "qrcode";
import Vue from "vue";
import * as XLSX from "xlsx";
import useActionsCampaigns from "../../views/Campaigns/_id/useActionsCampaigns";

export default {
  components: {
    LayoutDataTable,
  },
  props: {
    voucher_group_id: {
      type: Number,
    },
    voucher_group: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  async created() {
    if (this.$route.params.id !== "add") {
      this.fetchVouchers(this.$route.params.id);
    }
  },
  computed: {},
  data() {
    return {
      qrCodeData: "",
      required,
      appUtils,
      voucher_campaign: {},
      voucherGroup: {},
      voucherFields: [
        { key: "code", label: "Code" },
        { key: "created_at", label: "Ngày tạo" },
        { key: "is_redeemed", label: "Tình trạng" },
        { key: "qr_code", label: "QR Code" },
      ],
    };
  },
  watch: {
    currentPage(newVal) {
      this.currentPageVoucher = newVal;
      this.fetchVouchers();
    },
    perPage(newVal) {
      this.perPageVoucher = newVal;
      this.fetchVouchers();
    },
    searchQuery(newVal) {
      this.searchQueryVoucher = newVal;
      this.fetchVouchers();
    },
    voucher_group: {
      handler(data) {
        this.voucherGroup = { ...data };
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    async handleExportExcelFile() {
      const self = this;
      await self.fetchAllVouchers();
      if (!self.voucherAll?.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_no_data"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      const generateQR = async (text) => {
        try {
          const res = await QRCode.toDataURL(text);
          return res;
        } catch (err) {
          console.error(err);
        }
      };
      const qrCodeRaw = [];
      await Promise.all(
        self.voucherAll.map(async (item) => {
          const code = item.code;
          const res = await generateQR(code);
          qrCodeRaw.push(res);
        })
      );

      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet("Campaigns");

      ws.properties.defaultRowHeight = 80;

      // ws.insertRow(1, [
      //   `${self.voucher_campaign.name} (${moment(
      //     self.voucher_campaign.start_date
      //   ).format("DD/MM/YYYY")} - ${moment(
      //     self.voucher_campaign.end_date
      //   ).format("DD/MM/YYYY")})`,
      //   "",
      //   "",
      //   "",
      //   "",
      // ]);
      // ws.mergeCells("A1:E1");

      const startRow = 1;

      ws.getRow(startRow).border = {
        left: { style: "thin", color: { argb: "FFF000" } },
        right: { style: "thin", color: { argb: "FFF000" } },
        top: { style: "thin", color: { argb: "FFF000" } },
        bottom: { style: "thin", color: { argb: "6D4800" } },
      };

      ws.getRow(startRow).fill = {
        type: "pattern",
        pattern: "darkVertical",
        fgColor: { argb: "FFC000" },
      };

      ws.getRow(startRow).height = 20;

      ws.getRow(startRow).font = {
        // name: "Calibri",
        // size: 12,
        bold: true,
      };

      ws.columns = [
        {
          header: "Code",
          key: "code",
          width: 20,
        },
        {
          header: "Ngày bắt đầu",
          key: "start_date",
          width: 20,
          cellFormat: "mm/dd/yyyy",
        },
        {
          header: "Ngày kết thúc",
          key: "end_date",
          width: 20,
          cellFormat: "mm/dd/yyyy",
        },
        {
          header: "Tình trạng",
          key: "is_redeemed",
          width: 20,
          cellFormat: (cell) => {
            const item = {
              is_redeemed: cell.value,
            };
            return this.getStatusVoucher(item)?.text || "";
          },
        },
        {
          header: "QR Code",
          key: "qr_code",
          width: 20,
          cellFormat: (cell) => {
            cell.hyperlink = {
              url: cell.value,
              tooltip: "Click to view QR Code",
            };
          },
        },
      ];

      await Promise.all(
        self.voucherAll?.map(async (item, index) => {
          let rowNumber = index + startRow;
          const start_date =
            self.voucherGroup.start_date || self.voucher_campaign.start_date;
          const end_date =
            self.voucherGroup.end_date || self.voucher_campaign.end_date;

          ws.addRow({
            code: item.code,
            start_date: moment(start_date).format("DD/MM/YYYY"),
            end_date: moment(end_date).format("DD/MM/YYYY"),
            is_redeemed: self.getStatusVoucher(item)?.text || "",
            // qr_code: qrCodeRaw[index],
          });

          const imageData = await wb.addImage({
            base64: qrCodeRaw[index],
            extension: "jpeg",
          });

          await ws.addImage(imageData, {
            tl: { col: 4, row: rowNumber },
            ext: { width: 100, height: 100 },
          });
        })
      ).then(() => {
        wb.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `voucher_campaigns_${moment(new Date()).format(
            "DD-MM-YYYY"
          )}_${Date.now()}.xlsx`;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      });
    },
    async downloadCSV() {
      // Prepare worksheet data with a header row
      await this.fetchAllVouchers();
      const generateQR = async (text) => {
        try {
          const res = await QRCode.toDataURL(text);
          return res;
        } catch (err) {
          console.error(err);
        }
      };
      const qrCodeRaw = [];
      await Promise.all(
        this.voucherAll.map(async (item) => {
          const code = item.code;
          const res = await generateQR(code);
          qrCodeRaw.push(res);
        })
      );
      console.log(qrCodeRaw);
      const worksheetData = [
        [
          `${this.voucher_campaign.name} (${moment(
            this.voucher_campaign.start_date
          ).format("DD/MM/YYYY")} - ${moment(
            this.voucher_campaign.end_date
          ).format("DD/MM/YYYY")})`,
        ], // Title row
        ["Code", "Ngày bắt đầu", "Ngày kết thúc", "Tình trạng", "QR Code"], // Column headers
        ...this.voucherAll.map((item, index) => [
          item.code,
          moment(item.start_date).format("DD/MM/YYYY"),
          moment(item.end_date).format("DD/MM/YYYY"),
          item.is_redeemed === 0 ? "Chưa sử dụng" : "Đã sử dụng",
          qrCodeRaw[index],
        ]), // Data rows
      ];

      // Create a worksheet
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);

      // Apply styling to the first row (title row)
      ws["A1"].s = { fill: { fgColor: { rgb: "#FF0000" } } }; // Red background for A1
      ws["A1"].v = `${this.voucher_campaign.name} (${moment(
        this.voucher_campaign.start_date
      ).format("DD/MM/YYYY")} - ${moment(this.voucher_campaign.end_date).format(
        "DD/MM/YYYY"
      )})`; // Set cell value

      // Merge the first row to make it full-width
      ws["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }]; // Merges cells A1 to C1

      // Generate workbook and add worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Campaigns");

      // Create a download link for the XLSX file
      XLSX.writeFile(
        wb,
        `voucher_campaigns_${moment(new Date()).format(
          "DD-MM-YYYY"
        )}_${Date.now()}.xlsx`
      );
    },
    async fetchVouchers() {
      this.isLoadingVoucher = true;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getVouchers({
          page_size: this.perPage,
          page: this.currentPage,
          search: this.searchQuery,
          campaign_id: this.$route.params.id,
          voucher_group_id: this.voucher_group_id,
        })
        .then((response) => {
          const { data, total, voucher_campaign } = response.data || [];
          this.totalVouchers = total;
          this.vouchers = data.map((e) => ({
            code: e.code,
            created_at: e.created_at,
            is_redeemed: e.is_redeemed,
          }));
          this.voucher_campaign = voucher_campaign;
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingVoucher = false;
        });
    },
    async fetchAllVouchers() {
      this.isLoadingVoucher = true;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getAllVouchers({
          page_size: "-1",
          search: this.searchQuery,
          campaign_id: this.$route.params.id,
          voucher_group_id: this.voucher_group_id,
        })
        .then((response) => {
          const { data, voucher_campaign } = response.data || [];
          this.voucherAll = data.map((e) => ({
            code: e.code,
            created_at: e.created_at,
            is_redeemed: e.is_redeemed,
          }));
          this.voucher_campaign = voucher_campaign;
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingVoucher = false;
        });
    },
    async copyCode(code) {
      try {
        await navigator.clipboard.writeText(code);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Copy ${code} thành công`,
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.error("Failed to copy text:", error);
      }
    },
    openQrModal(voucher) {
      // Set the data for the QR code based on the voucher
      this.qrCodeData = voucher.code;
      this.$refs.qrModal.show();
    },
    async captureImageCanvas(doc) {
      const options = {
        dpi: 72,
        // scale: 10,
        backgroundColor: "#ffffff",
        image: { type: "jpeg", quality: 1 },
        letterRendering: true,
        allowTaint: true,
        logging: true,
        useCORS: true,
      };
      const dataCanvas = await html2canvas(doc, options)
        .then((canvas) => {
          const imgBase64Data = canvas.toDataURL("image/jpeg");
          return imgBase64Data;
        })
        .catch(() => {});

      return dataCanvas;
    },
    handleDownloadQrCode() {
      html2canvas(this.$refs.printMe)
        .then((canvas) => {
          canvas.toBlob((blob) => {
            saveAs(blob, `${this.qrCodeData}.png`);
          });
        })
        .catch((_error) => {
          console.log(_error);
        });
    },
    getStatusVoucher(data) {
      if (data?.is_redeemed === 1) {
        return {
          text: "Đã sử dụng",
          color: "text-danger",
        };
      } else if (this.voucherGroup?.status === 2) {
        return {
          text: "Đã huỷ",
          color: "text-danger",
        };
      } else if (this.checkVoucherDate()) {
        return {
          text: "Chưa sử dụng",
          color: "text-success",
        };
      } else {
        return {
          text: "Đã hết hạn",
          color: "text-warning",
        };
      }
    },
    checkVoucherDate() {
      const start_date =
        this.voucherGroup.start_date || this.voucher_campaign.start_date;
      const end_date =
        this.voucherGroup.end_date || this.voucher_campaign.end_date;
      return Number(start_date) <= Date.now() && Number(end_date) > Date.now();
    },
  },
};
</script>

<style lang="scss">
.table-vouchers {
  .m-2 {
    margin: 0 0 12px 0 !important;
  }
  .card {
    width: 100%;
    box-shadow: none;
  }
  .pagination-customize {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
  canvas {
    display: flex;
    margin: 0 auto;
  }
}
.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 40px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
}
</style>
