<template>
  <div>
    <b-modal
      size="lg"
      :visible="isShowVoucherModal"
      :title="form.id ? form.name : 'Thêm nhóm voucher'"
      centered
      cancel-variant="outline-secondary"
      :hide-footer="true"
      @hide="hideMethod"
      @change="(val) => $emit('update:is-show-voucher-modal', val)"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <h5
          v-if="
            data.status == VOUCHER_GROUP_STATUS.ACTIVE &&
            typeModal == TYPE_MODAL.VIEW &&
            checkValidDateVoucher
          "
          class="border-left-primary pl-50 my-1"
          style="border-left-width: 4px"
        >
          Thêm mới mã voucher
        </h5>
        <validation-observer ref="addVoucherValidation">
          <!-- form -->
          <b-form>
            <b-row>
              <b-col cols="6" v-if="typeModal !== TYPE_MODAL.VIEW">
                <b-form-group
                  :label="$t('lbl_voucher_group_name')"
                  class="required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_group_name')"
                    :rules="{
                      required: true,
                    }"
                  >
                    <b-form-input
                      placeholder="VD: Nhóm Voucher cho khách hàng mới"
                      v-model="form.name"
                      @blur="checkExistGroupName"
                    />
                    <small class="text-danger">{{
                      errors[0] || messageNameField
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6" v-if="typeModal !== TYPE_MODAL.VIEW">
                <b-form-group
                  :label="$t('lbl_voucher_group_value')"
                  class="required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_group_value')"
                    rules="required"
                  >
                    <div class="flex items-center gap-2">
                      <!-- <b-form-input
                        v-model.number="form.voucher_value"
                        type="number"
                        min="0"
                        :max="form.voucher_type === 2 ? 100 : null"
                        @input="handleVoucherValue"
                      /> -->
                      <cleave
                        v-model="form.voucher_value"
                        class="form-control"
                        :raw="false"
                        :options="cleaveOptions.number"
                        min="0"
                        :max="form.voucher_type === 2 ? 100 : null"
                        @input="(value) => handleVoucherValue(value)"
                      />
                      <div class="flex items-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :variant="
                            form.voucher_type === 1
                              ? 'primary'
                              : 'outline-primary'
                          "
                          style="
                            border-bottom-right-radius: 0;
                            border-top-right-radius: 0;
                          "
                          @click="() => handleType(1)"
                          >VNĐ</b-button
                        >
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :variant="
                            form.voucher_type === 2
                              ? 'primary'
                              : 'outline-primary'
                          "
                          style="
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;
                          "
                          @click="() => handleType(2)"
                          >%</b-button
                        >
                      </div>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                :cols="form.voucher_type === 2 ? 4 : 6"
                v-if="typeModal !== TYPE_MODAL.VIEW"
              >
                <b-form-group label="Ngày bắt đầu">
                  <validation-provider
                    #default="{ errors }"
                    name="Ngày bắt đầu"
                    vid="start"
                    :rules="{
                      date_between: [campaign.start_date, campaign.end_date],
                      date_before: [form.end_date],
                    }"
                  >
                    <b-form-datepicker
                      placeholder="Chọn ngày"
                      v-model="form.start_date"
                      :locale="$i18n.locale"
                      v-bind="$getCalendarLabel($i18n.locale)"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        weekday: 'long',
                      }"
                    ></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                :cols="form.voucher_type === 2 ? 4 : 6"
                v-if="typeModal !== TYPE_MODAL.VIEW"
              >
                <b-form-group label="Ngày kết thúc">
                  <validation-provider
                    #default="{ errors }"
                    name="Ngày kết thúc"
                    vid="end"
                    :rules="{
                      date_between: [campaign.start_date, campaign.end_date],
                      date_after: [form.start_date],
                    }"
                  >
                    <b-form-datepicker
                      placeholder="Chọn ngày"
                      v-model="form.end_date"
                      :locale="$i18n.locale"
                      v-bind="$getCalendarLabel($i18n.locale)"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        weekday: 'long',
                      }"
                    ></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                v-if="form.voucher_type === 2 && typeModal !== TYPE_MODAL.VIEW"
              >
                <b-form-group
                  :label="$t('lbl_voucher_group_max_voucher_value')"
                  class="mb-2 required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_group_max_voucher_value')"
                    :rules="{
                      required: true,
                    }"
                  >
                    <div class="flex items-center gap-2">
                      <!-- <b-form-input
                        v-model.number="form.max_voucher_value"
                        type="number"
                        min="0"
                        @input="(value) => handleSetPriceUnit(value)"
                      /> -->
                      <cleave
                        v-model="form.max_voucher_value"
                        class="form-control"
                        :raw="false"
                        :options="cleaveOptions.number"
                        @input="(value) => handleSetPrice(value)"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                v-if="
                  (typeModal === TYPE_MODAL.CREATE ||
                    typeModal === TYPE_MODAL.VIEW) &&
                  (data.status === VOUCHER_GROUP_STATUS.ACTIVE ||
                    !data.status) &&
                  checkValidDateVoucher
                "
              >
                <b-form-group :label="$t('lbl_voucher_prefix')">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_prefix')"
                    :rules="{
                      required: false,
                    }"
                  >
                    <b-form-input
                      placeholder="VD: HODO"
                      v-model="form.prefix"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                v-if="
                  (typeModal === TYPE_MODAL.CREATE ||
                    typeModal === TYPE_MODAL.VIEW) &&
                  (data.status === VOUCHER_GROUP_STATUS.ACTIVE ||
                    !data.status) &&
                  checkValidDateVoucher
                "
              >
                <b-form-group
                  :label="$t('lbl_number_of_code')"
                  class="required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_number_of_code')"
                    :rules="{
                      required: true,
                      min_value: 1,
                    }"
                  >
                    <b-form-input
                      placeholder="VD: 10"
                      v-model.number="form.number_of_code"
                      type="number"
                      min="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                v-if="
                  (typeModal === TYPE_MODAL.CREATE ||
                    typeModal === TYPE_MODAL.VIEW) &&
                  (data.status === VOUCHER_GROUP_STATUS.ACTIVE ||
                    !data.status) &&
                  checkValidDateVoucher
                "
              >
                <b-form-group
                  :label="$t('lbl_voucher_code_length')"
                  class="required"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_voucher_code_length')"
                    :rules="{
                      required: true,
                      between: [6, 10],
                    }"
                  >
                    <b-form-input
                      placeholder="VD: 10"
                      v-model.number="form.voucher_len"
                      type="number"
                      min="0"
                      max="10"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <!-- CTA Button -->
        <div class="d-flex">
          <div class="ml-auto">
            <b-button
              v-if="typeModal !== TYPE_MODAL.VIEW"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              @click="$emit('update:is-show-voucher-modal', false)"
              >Hủy</b-button
            >
            <template v-if="typeModal !== TYPE_MODAL.VIEW">
              <b-button
                v-if="
                  !data.status || data.status === VOUCHER_GROUP_STATUS.ACTIVE
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :loading="loading"
                @click="handleSubmit"
                >{{
                  typeModal === TYPE_MODAL.EDIT ? "Cập nhật" : "Thêm"
                }}</b-button
              >
            </template>
            <template v-else>
              <b-button
                v-if="checkValidDateVoucher"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :loading="loading"
                @click="handleSubmit"
                >{{
                  typeModal === TYPE_MODAL.EDIT ? "Cập nhật" : "Thêm"
                }}</b-button
              >
            </template>
          </div>
        </div>
        <!-- vouchers list -->
        <div v-if="typeModal === TYPE_MODAL.VIEW">
          <h5
            class="border-left-primary pl-50 mb-1"
            style="border-left-width: 4px"
          >
            Danh sách mã vouchers
          </h5>
          <b-collapse v-model="isTableVisible">
            <TableVouchers :voucher_group_id="form.id" :voucher_group="form" />
          </b-collapse>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import TableVouchers from "@/components/Campaign/TableVouchers.vue";
import i18n from "@/libs/i18n";
import { TYPE_MODAL, VOUCHER_GROUP_STATUS } from "@/utils/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
  BOverlay,
  BRow,
  VBModal,
} from "bootstrap-vue";
import { isEmpty } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Vue from "vue";
import Cleave from "vue-cleave-component";
import Ripple from "vue-ripple-directive";
import useActionsCampaigns from "../../views/Campaigns/_id/useActionsCampaigns";

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    TableVouchers,
    Cleave,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    isShowVoucherModal: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      required: true,
      default: () => {},
    },
    typeModal: {
      type: Number, // TYPE_MODAL
      required: true,
    },
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  data() {
    return {
      form: {},
      voucher_percent: 0,
      voucher_amount: 0,
      required,
      loading: false,
      selectedProducts: [],
      isTableVisible: true, // Data property to control the collapse state
      messageNameField: "", // Data property to control the collapse state
      TYPE_MODAL,
      VOUCHER_GROUP_STATUS,
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
    };
  },
  mounted() {},
  watch: {
    // Watch for changes to the `data` prop
    data: {
      deep: true,
      immediate: true, // Trigger this watcher when component is mounted
      handler(newValue) {
        this.form = isEmpty(newValue)
          ? {
              voucher_type: 1,
            }
          : { ...newValue };
      },
    },
  },
  computed: {
    checkValidDateVoucher() {
      const start_date = this.data.start_date || this.campaign.start_date;
      const end_date = this.data.end_date || this.campaign.end_date;
      return Number(start_date) <= Date.now() && Number(end_date) > Date.now();
    },
  },
  methods: {
    hideMethod() {
      this.$emit("closeModalVoucher");
    },
    handleVoucherValue(value) {
      this.form.voucher_value =
        Number(value?.toString()?.replaceAll(",", "")) || 0;
      if (this.form.voucher_type === 1) {
        this.voucher_amount = this.form.voucher_value;
      } else {
        this.voucher_percent = this.form.voucher_value;
      }
    },
    handleType(type) {
      this.form.voucher_type = type;
      if (type === 1) {
        this.form.voucher_value = this.voucher_amount || 0;
      } else {
        this.form.voucher_value = this.voucher_percent || 0;
      }
    },
    handleSetPrice(value) {
      this.form.max_voucher_value =
        Number(value?.toString()?.replaceAll(",", "")) || 0;
    },
    async checkExistGroupName() {
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .checkExistGroupName({
          voucher_campaign_id: this.campaign.id,
          name: this.form.name,
        })
        .then((response) => {
          const existed = response.data || false;
          this.messageNameField =
            !existed || this.data.name === this.form.name
              ? ""
              : this.$t("campaigns.lbl_campaign_group_name_exist");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    async createVoucherGroup() {
      try {
        const response = await Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .createVoucherGroup({
            name: this.form.name,
            voucher_campaign_id: parseInt(this.$route.params.id),
            voucher_value: this.form.voucher_value,
            voucher_type: this.form.voucher_type,
            max_voucher_value:
              this.form.voucher_type === 1
                ? this.form.voucher_value
                : this.form.max_voucher_value,
            workspace_id: 0,
            start_date: this.form.start_date
              ? moment(this.form.start_date).startOf("day").valueOf()
              : 0,
            end_date: this.form.end_date
              ? moment(this.form.end_date).endOf("day").valueOf()
              : 0,
          });
        return response.data.id; // Return the voucher group ID
      } catch (error) {
        console.error("Error creating voucher group:", error);
        this.showToastError();
        throw error; // Rethrow error to handle in handleSubmit
      }
    },
    async updateVoucherGroup() {
      try {
        await Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .updateVoucherGroup({
            id: this.form.id,
            name: this.form.name,
            voucher_campaign_id: parseInt(this.$route.params.id),
            voucher_value: this.form.voucher_value,
            voucher_type: this.form.voucher_type,
            max_voucher_value:
              this.form.voucher_type === 1
                ? this.form.voucher_value
                : this.form.max_voucher_value,
            workspace_id: 0,
            start_date: this.form.start_date
              ? moment(this.form.start_date).startOf("day").valueOf()
              : 0,
            end_date: this.form.end_date
              ? moment(this.form.end_date).endOf("day").valueOf()
              : 0,
          });
      } catch (error) {
        console.error("Error creating voucher group:", error);
        this.showToastError();
        throw error; // Rethrow error to handle in handleSubmit
      } finally {
        this.rowSelected = {};
      }
    },
    async generateVouchers(voucherGroupId) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest("CampaignRequest")
          .createVouchers({
            voucher_campaign_id: parseInt(this.$route.params.id),
            voucher_group_id: voucherGroupId,
            number_of_code: this.form.number_of_code,
            voucher_len: this.form.voucher_len,
            prefix: this.form.prefix,
          });
        console.log("Vouchers generated:", response);
      } catch (error) {
        console.error("Error generating vouchers:", error);
        this.showToastError();
        throw error; // Rethrow error to handle in handleSubmit
      }
    },

    showToastError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_error_fetching_data"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    async handleSubmit() {
      const isValid = await this.$refs.addVoucherValidation.validate();
      if (!isValid || this.messageNameField) return;

      this.loading = true;

      try {
        if (this.typeModal === TYPE_MODAL.CREATE) {
          // CREATE VOUCHER GROUP
          // Step 1: Create Voucher Group
          const voucherGroupId = await this.createVoucherGroup();

          // Step 2: Generate Vouchers
          await Promise.all([this.generateVouchers(voucherGroupId)]);
        } else if (this.typeModal === TYPE_MODAL.EDIT) {
          // UPDATE VOUCHER GROUP
          await this.updateVoucherGroup();
        } else if (this.typeModal === TYPE_MODAL.VIEW) {
          // GENERATE VOUCHER
          await this.generateVouchers(this.form.id);
        }

        // Emit to close the modal and commit data
        this.$emit("commitData");
        this.$emit("update:is-show-voucher-modal", false);
      } catch (error) {
        // Error already handled in individual functions
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
.collapsible {
  overflow: hidden;
  height: 0; /* Start with height 0 */
  transition: height 100ms ease; /* Smooth height transition */
}

.collapsible.is-open {
  height: auto; /* Allow height to expand */
}

.content {
  padding: 10px; /* Padding for content */
}
</style>
