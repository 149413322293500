var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"lg","visible":_vm.isShowVoucherModal,"title":_vm.form.id ? _vm.form.name : 'Thêm nhóm voucher',"centered":"","cancel-variant":"outline-secondary","hide-footer":true},on:{"hide":_vm.hideMethod,"change":function (val) { return _vm.$emit('update:is-show-voucher-modal', val); }}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","spinner-variant":"primary","spinner-type":"grow","spinner-small":""}},[(
          _vm.data.status == _vm.VOUCHER_GROUP_STATUS.ACTIVE &&
          _vm.typeModal == _vm.TYPE_MODAL.VIEW &&
          _vm.checkValidDateVoucher
        )?_c('h5',{staticClass:"border-left-primary pl-50 my-1",staticStyle:{"border-left-width":"4px"}},[_vm._v(" Thêm mới mã voucher ")]):_vm._e(),_c('validation-observer',{ref:"addVoucherValidation"},[_c('b-form',[_c('b-row',[(_vm.typeModal !== _vm.TYPE_MODAL.VIEW)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('lbl_voucher_group_name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('lbl_voucher_group_name'),"rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"VD: Nhóm Voucher cho khách hàng mới"},on:{"blur":_vm.checkExistGroupName},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.messageNameField))])]}}],null,false,55087787)})],1)],1):_vm._e(),(_vm.typeModal !== _vm.TYPE_MODAL.VIEW)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('lbl_voucher_group_value')}},[_c('validation-provider',{attrs:{"name":_vm.$t('lbl_voucher_group_value'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center gap-2"},[_c('cleave',{staticClass:"form-control",attrs:{"raw":false,"options":_vm.cleaveOptions.number,"min":"0","max":_vm.form.voucher_type === 2 ? 100 : null},on:{"input":function (value) { return _vm.handleVoucherValue(value); }},model:{value:(_vm.form.voucher_value),callback:function ($$v) {_vm.$set(_vm.form, "voucher_value", $$v)},expression:"form.voucher_value"}}),_c('div',{staticClass:"flex items-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticStyle:{"border-bottom-right-radius":"0","border-top-right-radius":"0"},attrs:{"variant":_vm.form.voucher_type === 1
                            ? 'primary'
                            : 'outline-primary'},on:{"click":function () { return _vm.handleType(1); }}},[_vm._v("VNĐ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticStyle:{"border-bottom-left-radius":"0","border-top-left-radius":"0"},attrs:{"variant":_vm.form.voucher_type === 2
                            ? 'primary'
                            : 'outline-primary'},on:{"click":function () { return _vm.handleType(2); }}},[_vm._v("%")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,783366735)})],1)],1):_vm._e(),(_vm.typeModal !== _vm.TYPE_MODAL.VIEW)?_c('b-col',{attrs:{"cols":_vm.form.voucher_type === 2 ? 4 : 6}},[_c('b-form-group',{attrs:{"label":"Ngày bắt đầu"}},[_c('validation-provider',{attrs:{"name":"Ngày bắt đầu","vid":"start","rules":{
                    date_between: [_vm.campaign.start_date, _vm.campaign.end_date],
                    date_before: [_vm.form.end_date],
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-datepicker',_vm._b({attrs:{"placeholder":"Chọn ngày","locale":_vm.$i18n.locale,"date-format-options":{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      weekday: 'long',
                    }},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}},'b-form-datepicker',_vm.$getCalendarLabel(_vm.$i18n.locale),false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,97618884)})],1)],1):_vm._e(),(_vm.typeModal !== _vm.TYPE_MODAL.VIEW)?_c('b-col',{attrs:{"cols":_vm.form.voucher_type === 2 ? 4 : 6}},[_c('b-form-group',{attrs:{"label":"Ngày kết thúc"}},[_c('validation-provider',{attrs:{"name":"Ngày kết thúc","vid":"end","rules":{
                    date_between: [_vm.campaign.start_date, _vm.campaign.end_date],
                    date_after: [_vm.form.start_date],
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-datepicker',_vm._b({attrs:{"placeholder":"Chọn ngày","locale":_vm.$i18n.locale,"date-format-options":{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      weekday: 'long',
                    }},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}},'b-form-datepicker',_vm.$getCalendarLabel(_vm.$i18n.locale),false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2140183147)})],1)],1):_vm._e(),(_vm.form.voucher_type === 2 && _vm.typeModal !== _vm.TYPE_MODAL.VIEW)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2 required",attrs:{"label":_vm.$t('lbl_voucher_group_max_voucher_value')}},[_c('validation-provider',{attrs:{"name":_vm.$t('lbl_voucher_group_max_voucher_value'),"rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center gap-2"},[_c('cleave',{staticClass:"form-control",attrs:{"raw":false,"options":_vm.cleaveOptions.number},on:{"input":function (value) { return _vm.handleSetPrice(value); }},model:{value:(_vm.form.max_voucher_value),callback:function ($$v) {_vm.$set(_vm.form, "max_voucher_value", $$v)},expression:"form.max_voucher_value"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2355853052)})],1)],1):_vm._e(),(
                (_vm.typeModal === _vm.TYPE_MODAL.CREATE ||
                  _vm.typeModal === _vm.TYPE_MODAL.VIEW) &&
                (_vm.data.status === _vm.VOUCHER_GROUP_STATUS.ACTIVE ||
                  !_vm.data.status) &&
                _vm.checkValidDateVoucher
              )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('lbl_voucher_prefix')}},[_c('validation-provider',{attrs:{"name":_vm.$t('lbl_voucher_prefix'),"rules":{
                    required: false,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"VD: HODO"},model:{value:(_vm.form.prefix),callback:function ($$v) {_vm.$set(_vm.form, "prefix", $$v)},expression:"form.prefix"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1081261802)})],1)],1):_vm._e(),(
                (_vm.typeModal === _vm.TYPE_MODAL.CREATE ||
                  _vm.typeModal === _vm.TYPE_MODAL.VIEW) &&
                (_vm.data.status === _vm.VOUCHER_GROUP_STATUS.ACTIVE ||
                  !_vm.data.status) &&
                _vm.checkValidDateVoucher
              )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('lbl_number_of_code')}},[_c('validation-provider',{attrs:{"name":_vm.$t('lbl_number_of_code'),"rules":{
                    required: true,
                    min_value: 1,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"VD: 10","type":"number","min":"0"},model:{value:(_vm.form.number_of_code),callback:function ($$v) {_vm.$set(_vm.form, "number_of_code", _vm._n($$v))},expression:"form.number_of_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,278152929)})],1)],1):_vm._e(),(
                (_vm.typeModal === _vm.TYPE_MODAL.CREATE ||
                  _vm.typeModal === _vm.TYPE_MODAL.VIEW) &&
                (_vm.data.status === _vm.VOUCHER_GROUP_STATUS.ACTIVE ||
                  !_vm.data.status) &&
                _vm.checkValidDateVoucher
              )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('lbl_voucher_code_length')}},[_c('validation-provider',{attrs:{"name":_vm.$t('lbl_voucher_code_length'),"rules":{
                    required: true,
                    between: [6, 10],
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"VD: 10","type":"number","min":"0","max":"10"},model:{value:(_vm.form.voucher_len),callback:function ($$v) {_vm.$set(_vm.form, "voucher_len", _vm._n($$v))},expression:"form.voucher_len"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1464109197)})],1)],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto"},[(_vm.typeModal !== _vm.TYPE_MODAL.VIEW)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$emit('update:is-show-voucher-modal', false)}}},[_vm._v("Hủy")]):_vm._e(),(_vm.typeModal !== _vm.TYPE_MODAL.VIEW)?[(
                !_vm.data.status || _vm.data.status === _vm.VOUCHER_GROUP_STATUS.ACTIVE
              )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.typeModal === _vm.TYPE_MODAL.EDIT ? "Cập nhật" : "Thêm"))]):_vm._e()]:[(_vm.checkValidDateVoucher)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.typeModal === _vm.TYPE_MODAL.EDIT ? "Cập nhật" : "Thêm"))]):_vm._e()]],2)]),(_vm.typeModal === _vm.TYPE_MODAL.VIEW)?_c('div',[_c('h5',{staticClass:"border-left-primary pl-50 mb-1",staticStyle:{"border-left-width":"4px"}},[_vm._v(" Danh sách mã vouchers ")]),_c('b-collapse',{model:{value:(_vm.isTableVisible),callback:function ($$v) {_vm.isTableVisible=$$v},expression:"isTableVisible"}},[_c('TableVouchers',{attrs:{"voucher_group_id":_vm.form.id,"voucher_group":_vm.form}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }