<template>
  <div>
    <b-card class="campaign-add-wrapper">
      <div>
        <validation-observer ref="addCampaignValidation">
          <b-form>
            <b-row>
              <b-col cols="12" md="12" xxl="12" order-md="first">
                <b-row>
                  <b-col cols="12">
                    <h5
                      class="border-left-primary pl-50"
                      style="border-left-width: 4px"
                    >
                      {{ "Thông tin Campaign" }}
                    </h5>
                  </b-col>

                  <b-col :cols="4">
                    <b-form-group
                      :label="$t('lbl_campaigns_name')"
                      class="mb-2 required"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('lbl_campaigns_name')"
                        rules="required"
                      >
                        <b-form-input v-model="form.name" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- <b-col :cols="6">
                    <b-form-group label="Ngân sách">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('campaigns.lbl_campaign_budget')"
                        rules="required"
                      >
                        <b-form-input
                          type="text"
                          placeholder="VD: 10"
                          v-model="formattedBudget"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <b-col :cols="4">
                    <b-form-group label="Ngày bắt đầu">
                      <b-form-datepicker
                        placeholder="Chọn ngày bắt đầu"
                        v-model="form.start_date"
                        :locale="$i18n.locale"
                        v-bind="$getCalendarLabel($i18n.locale)"
                        @input="validateDates"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-col>

                  <b-col :cols="4">
                    <b-form-group
                      label="Ngày kết thúc"
                      :state="endDateValid"
                      invalid-feedback="Ngày kết thúc phải sau ngày bắt đầu"
                    >
                      <b-form-datepicker
                        placeholder="Chọn ngày kết thúc"
                        v-model="form.end_date"
                        :locale="$i18n.locale"
                        v-bind="$getCalendarLabel($i18n.locale)"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col :cols="12">
                    <b-form-group
                      :label="$t('lbl_short_description')"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('lbl_short_description')"
                      >
                        <b-form-textarea v-model="form.description" rows="3" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group> </b-col
                  ><b-col cols="12" class="d-flex justify-content-end mt-1">
                    <div class="mb-1">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        :to="{ name: 'campaigns' }"
                        class="mr-1"
                        variant="outline-secondary"
                        >{{ $t("lbl_cancel") }}</b-button
                      >
                      <b-button
                        v-if="form.id"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        :disabled="isLoading"
                        @click="updateCampaign"
                        >{{ $t("lbl_update") }}</b-button
                      >
                      <b-button
                        v-else
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        :disabled="isLoading"
                        @click="addCampaign"
                        >{{ $t("lbl_add_new") }}</b-button
                      >
                    </div>
                  </b-col>
                  <b-col :cols="12"
                    ><TableGroupVouchers :campaign="detailCampaign"
                  /></b-col>
                  <b-col :cols="12"><TableProducts :campaign="form" /></b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import { required } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import appUtils from "@/utils/appUtils";
import "cleave.js/dist/addons/cleave-phone.vi";
import useActionsCampaigns from "./useActionsCampaigns";
import Vue from "vue";
import i18n from "@/libs/i18n";
import TableGroupVouchers from "@/components/Campaign/TableGroupVouchers.vue";
import TableProducts from "@/components/Campaign/TableProducts.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "AddAndUpdateCampaign",
  components: {
    ValidationProvider,
    ValidationObserver,
    TableGroupVouchers,
    TableProducts,
  },
  directives: {
    Ripple,
  },
  setup() {
    const mixData = useActionsCampaigns();

    return { ...mixData };
  },
  computed: {
    endDateValid() {
      if (!this.form.start_date || !this.form.end_date) return null; // Only validate if both dates are selected
      return new Date(this.form.end_date) >= new Date(this.form.start_date);
    },
    formattedBudget: {
      get() {
        // Format the campaign_budget as a string with thousand separators
        return new Intl.NumberFormat().format(this.form.campaign_budget);
      },
      set(value) {
        // Parse and remove non-numeric characters
        const numericValue = parseFloat(value.replace(/\D/g, "")) || 0;
        this.form.campaign_budget = numericValue;
      },
    },
  },

  data() {
    return {
      required,
      appUtils,
      isShowModalProduct: false,
    };
  },
  async created() {
    if (this.$route.params.id !== "add") {
      this.campaignId = this.$route.params.id;
      this.fetchCampaign(this.campaignId);
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.path === "/campaigns/add") {
      to.meta.pageTitle = "lbl_campaigns_add";
    } else {
      to.meta.pageTitle = "lbl_campaigns_detail";
    }
    next();
  },

  mounted() {
    this.$router.currentRoute.meta.pageTitle = "this.$i18n.t(this.pageTitle)";
  },
  methods: {
    formatInput(event) {
      // Get only numeric characters from the input
      const value = event.target.value.replace(/\D/g, "");
      this.form.campaign_budget = parseFloat(value) || 0;
    },
    validateDates() {
      if (!this.endDateValid && this.form.end_date) {
        // Display a warning or take some action if end_date is before start_date
        this.$bvToast.toast("Ngày kết thúc phải sau ngày bắt đầu", {
          title: "Lỗi ngày",
          variant: "danger",
          solid: true,
        });
      }
    },
    openModalProduct() {
      this.isShowModalProduct = true;
    },
    async commitData(data) {
      console.log({ data });
    },
    async fetchCampaign(campaignId) {
      this.isLoading = true;
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getCampaign(campaignId)
        .then((response) => {
          const data = response.data || {};
          this.form =
            {
              ...data,
              start_date: new Date(data.start_date),
              end_date: new Date(data.end_date),
            } || {};

          this.detailCampaign =
            {
              ...data,
              start_date: new Date(data.start_date),
              end_date: new Date(data.end_date),
            } || {};
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async fetchVouchers(campaignId) {
      this.isLoadingVoucher = true;
      console.log({
        campaignId,
        perPage: this.perPage,
        currentPage: this.currentPage,
      });
      await Vue.prototype.$rf
        .getRequest("CampaignRequest")
        .getVouchers({
          page_size: this.perPage,
          page: this.currentPage,
          search: this.searchQuery,
          campaign_id: campaignId,
        })
        .then((response) => {
          const { data, total } = response.data || [];
          this.totalVouchers = total;
          this.vouchers = data.map((e) => ({
            code: e.code,
            created_at: e.created_at,
            is_redeemed: e.is_redeemed,
          }));
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t("lbl_error_fetching_data"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.isLoadingVoucher = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
